export const defaultStateUser = {
    userPrincipalId: "Not connected",
    userAccountId: "",
    userUuid: "",
    referralLink: "",
    referredBy: "",
    referredByExpiresAt: "",
    icpBalance: "0",
    icpUsdBalance: "0",
    ckbtcBalance: "0",
    ckbtcUsdBalance: "0",
    ckethBalance: "0",
    ckethUsdBalance: "0",
    kongBalance: "0",
    kongUsdBalance: "0",
    ckusdcBalance: "0",
    ckusdcUsdBalance: "0",
    ckusdtBalance: "0",
    ckusdtUsdBalance: "0",
    bitsBalance: "0",
    bitsUsdBalance: "0",
    dkpBalance: "0",
    dkpUsdBalance: "0",
    chatBalance: "0",
    chatUsdBalance: "0",
    nanasBalance: "0",
    nanasUsdBalance: "0",
    nd64Balance: "0",
    nd64UsdBalance: "0",
    wtnBalance: "0",
    wtnUsdBalance: "0",
    yugeBalance: "0",
    yugeUsdBalance: "0",
    nicpBalance: "0",
    nicpUsdBalance: "0",
    alpacalbBalance: "0",
    alpacalbUsdBalance: "0",
    partyBalance: "0",
    partyUsdBalance: "0",
    sneedBalance: "0",
    sneedUsdBalance: "0",
    clownBalance: "0",
    clownUsdBalance: "0",
    damonicBalance: "0",
    damonicUsdBalance: "0",
    exeBalance: "0",
    exeUsdBalance: "0",
    wumboBalance: "0",
    wumboUsdBalance: "0",
    mcsBalance: "0",
    mcsUsdBalance: "0",
    bobBalance: "0",
    bobUsdBalance: "0",
    burnBalance: "0",
    burnUsdBalance: "0",
    ntnBalance: "0",
    ntnUsdBalance: "0",
    dcdBalance: "0",
    dcdUsdBalance: "0",
    gldgovBalance: "0",
    gldgovUsdBalance: "0",
    owlBalance: "0",
    owlUsdBalance: "0",
    ogyBalance: "0",
    ogyUsdBalance: "0",
    fplBalance: "0",
    fplUsdBalance: "0",
    dittoBalance: "0",
    dittoUsdBalance: "0",
    icvcBalance: "0",
    icvcUsdBalance: "0",
    gldtBalance: "0",
    gldtUsdBalance: "0",
    ghostBalance: "0",
    ghostUsdBalance: "0",
    ctzBalance: "0",
    ctzUsdBalance: "0",
    elnaBalance: "0",
    elnaUsdBalance: "0",
    dogmiBalance: "0",
    dogmiUsdBalance: "0",
    estBalance: "0",
    estUsdBalance: "0",
    pandaBalance: "0",
    pandaUsdBalance: "0",
    kinicBalance: "0",
    kinicUsdBalance: "0",
    dolrBalance: "0",
    dolrUsdBalance: "0",
    traxBalance: "0",
    traxUsdBalance: "0",
    motokoBalance: "0",
    motokoUsdBalance: "0",
    ckpepeBalance: "0",
    ckpepeUsdBalance: "0",
    ckshibBalance: "0",
    ckshibUsdBalance: "0",
    dodBalance: "0",
    dodUsdBalance: "0",
    kongBalance: "0",
    kongUsdBalance: "0",
};